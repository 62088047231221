import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Img from "gatsby-image"

const GalleryPage = ({ data }) => {
  const { edges } = data.allContentfulGalleryImage
  const arr = new Array(8)
  for (let i = 0; i < 8; i++) {
    arr[i] = <img src="https://picsum.photos/id/237/300/200" />
  }
  return (
    <Layout>
      <SiteMetadata title="Gallery" description="Images of Tara Segda" />
      <div className=" bg-gray-100">
        <section className="container overflow-hidden h-full">
          <div className="grid md:grid-cols-3 gap-4 py-8 sm:min-h-screen">
            {edges.map(x => (
              <div key={x.node.order}>
                <Img
                  fluid={x.node.image.fluid}
                  alt={x.node.alt}
                  imgStyle={{ objectFit: "cover" }}
                  className="rounded-md shadow-md"
                />
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default GalleryPage
export const query = graphql`
  query GalleryQuery {
    allContentfulGalleryImage(sort: { fields: order }) {
      edges {
        node {
          alt
          order
          image {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

// {edges.map(x => (
//   <div className="lg:w-1/3 md:w-1/2 sm:w-full p-1 md:p-2" key={x.node.order}>
//     <Img fluid={x.node.image.fluid} alt={x.node.alt} />
//   </div>
// ))}

// img outer div styles removed to try grid
// className="flex flex-wrap lg:w-1/3 md:w-1/2 sm:w-full"
